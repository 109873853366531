import React from 'react'
import Styled from 'styled-components'

import Error from '../images/Error.svg'

const Main = Styled.main`


height:95vh;
display:flex;
justify-content:center;

img {
	width:100%;
	height:100%;
	object-fit:contain;
`

const ErrorPage = () => {
	return (
		<Main>

			<img src={Error} alt="Error - page not found"/>

		</Main>
	)
}

export default ErrorPage
